<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">

        <Toolbar class="p-mb-4">
          <template #start>
            <div style="font-size: 1.4em; color: inherit">{{ $t('Sales invoices') }}</div>
          </template>
          <template #end>
            <div class="p-d-flex p-ai-center p-mr-2" v-if="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin || $store.state.user.role === constants.userRoles.accountant">
              <!--            <InputSwitch id="form-agree-inputswitch" class="p-mr-2" v-model="privatePolicyIsAccepted"/>-->
              <Checkbox :disabled="dataTableIsLoading" @change="getItems" id="sales-invoices-show-only-unpaid-inputswitch" :binary="true" v-model="showOnlyUnpaid"/>
              <div>
                <label for="sales-invoices-show-only-unpaid-inputswitch">
                  <div class="pointer p-p-2">Show only unpaid</div>
                </label>
              </div>
            </div>
            <DatesRangeCalendar :disabled="dataTableIsLoading" :isEmptyByDefault="true" @change-dates-range="changeDatesRange" v-show="true" class="p-mr-2"/>
            <SearchInput :disabled="dataTableIsLoading" @change-processed-search-value="changeProcessedSearchValue" v-model="searchData" @show-spinner="isLoading = true"/>
          </template>
        </Toolbar>

        <!--  Desktop DataTable -->
        <Spinner v-if="isLoading"></Spinner>
<!--        <DesktopDataTable v-else-if="!isLoading && !$store.state.tabletLayout"-->
        <DesktopDataTable v-else
                          :dataTableIsLoading="dataTableIsLoading"
                          :showHeader="false"
                          :tableName="$t('Sales invoices')"
                          :itemsArrayIsEmpty="itemsArrayIsEmpty"
                          :sort="sort"
                          :showSearchInput="false"
                          :actionButtonsCount="actionButtonsCount"
                          @sort-items="sortItems"
                          :headers="[
              // {name: 'number', title: 'Number', sortable: true},
              // {name: 'export_number', title: 'SA', width: '60px', sortable: false, isHidden: !settings.useSmartAccounts},
              // {name: 'order', title: 'Order', sortable: false},
              // {name: 'customer', title: 'Customer', width: '', sortable: false},
              // {name: 'issued', title: 'Issued', sortable: true},
              // {name: 'payment_due', title: 'Payment due', sortable: true},
              // {name: 'overdue', title: '', width: '45px', sortable: false},
              // {name: 'grand_total', title: 'Grand total', sortable: true},
              // {name: 'unpaid', title: 'Unpaid', sortable: true},
              // {name: 'method', title: 'Method', width: '78px', sortable: false},
              // {name: '', title: '', width: '45px', sortable: false},
              // {name: 'state', title: 'State', width: '9%', sortable: true},
              // {name: 'sent_at', title: '', width: '45px', sortable: false},
              // {name: 'created_at', title: $t('Created'), sortable: true},
              {name: 'number', title: 'Number', sortable: true},
              {name: 'export_number', title: 'SA', sortable: false, isHidden: !settings.useSmartAccounts},
              {name: 'order', title: 'Order', sortable: false},
              {name: 'customer', title: 'Customer', sortable: false},
              {name: 'issued', title: 'Issued', sortable: true},
              {name: 'payment_due', title: 'Payment due', sortable: true},
              {name: 'overdue', title: '', sortable: false},
              {name: 'grand_total', title: 'Sum', sortable: true},
              {name: 'unpaid', title: 'Unpaid', sortable: true},
              {name: 'method', title: 'Method', sortable: false},
              {name: '', title: '', sortable: false},
              {name: 'state', title: 'State', sortable: true},
              {name: 'sent_at', title: '', sortable: false},
              {name: 'created_at', title: $t('Created'), sortable: true},
          ]">
          <template v-slot:body>
            <tr v-for="(item, index) of items"
                :key="index"
                :class="{'updated-item': isItemUpdated(item.id)}">
              <td>
                <span>{{ item.number }}</span>
              </td>
              <td v-if="settings.useSmartAccounts">
                <i v-if="item.export_number || item.export_id" class="ti-link" v-tooltip.top="item.export_number"></i>
                <i v-if="item.changed_after_export" class="pi pi-exclamation-triangle warning-color p-ml-1" v-tooltip.top="'Changed after export'"></i>
              </td>
              <td>
                <router-link v-if="item.order" :to="{ path: `/orders/${item.order.number}` }" target="_blank">{{ item.order.number }}</router-link>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <div v-if="item.customer" class="p-d-flex p-align-center">
                  <CustomerStatusHistoryButton
                      :customerStatus="item.customer.customerStatus"
                      :customerId="item.user_id"
                      @change-customer-status="changeCustomerStatus"/>
                  <router-link :to="{ path: `/customers/${item.customer.id}` }" target="_blank">
                    <span v-if="item.customer.type === constants.userTypes.legal_entity && item.customer.company_name">{{ item.customer.company_name }}</span>
                    <span v-else>{{ item.customer.first_name }} {{ item.customer.last_name }}</span>
                  </router-link>
                </div>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.issued">{{ formatDay(item.issued) }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.payment_due" :class="{'warning-color': (countOverdueDays(item.payment_due) <= 0) && item.state !== 5 }">{{ formatDay(item.payment_due) }}</span>
                <i v-else class="ti-minus"></i>
              </td>
              <td>
                <span v-if="item.payment_due && item.state !== 5" :class="{'warning-color': countOverdueDays(item.payment_due) <= 0 }">{{ countOverdueDays(item.payment_due) }}</span>
              </td>
              <td>
                <span>{{ formatCurrency(item.grand_total) }}</span>
              </td>
              <td>
                <span>{{ formatCurrency(item.unpaid) }}</span>
              </td>
              <td>
                <span v-for="(method, index) of generatePaymentsMethods(item.payments)" :key="index">
                  <span style="padding: 3px"><i :class="{'ti-money': method === 1, 'ti-credit-card': method === 3, 'ti-world': method === 5}"></i></span>
                </span>
              </td>
              <td>
                <GetSalesInvoicePdfButtonSM :item="item" :disableEditButton="disableEditButton" :showUpdatedItemPdfSpinner="showUpdatedItemPdfSpinner" :isItemUpdated="isItemUpdated(item.id)"/>
              </td>
              <td>
                <div  class="p-d-flex p-flex-wrap p-ai-center">
                  <div class="state-badge" :class="SalesInvoiceStates.find(state => state.num === item.state)?.color || ''">{{ SalesInvoiceStates.find(state => state.num === item.state)?.label[$i18n.locale] || '' }}</div>
                </div>
              </td>
              <td>
                <div v-if="item.sent_at" v-tooltip.top="'Sent at: ' + formatDate(item.sent_at)" class="p-d-flex p-jc-center p-ai-center p-p-2">
                  <i style="font-size: 1.1em" class="ti-email"></i>
                </div>
              </td>
              <td class="table-date-line">
                <span v-if="item.created_at">
                  {{ formatDate(item.created_at) || '' }},
                </span>
                <span v-if="item.creator" class="table-date-line">
                  {{ item.creator.first_name }} {{ item.creator.last_name }}
                </span>
              </td>
              <td>
                <div class="p-d-flex">
                  <EditButton :disabled="disableEditButton && isItemUpdated(item.id) || !allDataLoaded || dataTableIsLoading"
                              @click="editItem(item)" />
                  <DeleteButton @click="confirmItemDelete(item)"
                                :disabled="!allDataLoaded || dataTableIsLoading"
                                v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin"/>
                </div>
              </td>
            </tr>
          </template>
        </DesktopDataTable>

        <!--  Mobile DataTable -->
<!--        <MobileDataTable v-else-if="!isLoading && $store.state.tabletLayout"-->
<!--                         :tableName="$t('Sales invoices')"-->
<!--                         :showSearchInput="false"-->
<!--                         :itemsArrayIsEmpty="itemsArrayIsEmpty">-->
<!--          <template v-slot:body>-->
<!--            <tr :class="{'updated-item': isItemUpdated(item.id)}"-->
<!--                v-for="(item, index) of items"-->
<!--                :key="index">-->
<!--              <td>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center p-pt-2">-->
<!--                  <div class="p-text-bold mobile-datatable__title">Number:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <span>{{ item.number }} </span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">Sa:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <span>{{ item.secondary_number }}</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">Order:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <span v-if="item.order"><router-link :to="{ path: `/orders/${item.order.number}` }" target="_blank">{{ item.order.number }}</router-link></span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">Customer:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <router-link v-if="item.customer" :to="{ path: `/customers/${item.customer.id}` }" target="_blank">-->
<!--                      <span v-if="item.customer.type === constants.userTypes.legal_entity && item.customer.company_name">{{ item.customer.company_name }}</span>-->
<!--                      <span v-else>{{ item.customer.first_name }} {{ item.customer.last_name }}</span>-->
<!--                    </router-link>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">Issued:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <span v-if="item.issued">{{ formatDay(item.issued) }}</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">Payment due:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <span v-if="item.payment_due"  :class="{'warning-color': (countOverdueDays(item.payment_due) >= 0) && item.state !== 5 }">{{ formatDay(item.payment_due) }}</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">Overdue:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <span v-if="item.payment_due && item.state !== 5" :class="{'warning-color': (countOverdueDays(item.payment_due) >= 0) && item.state !== 5 }">{{ countOverdueDays(item.payment_due) }}</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">Grand total:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <span>{{ formatCurrency(+item.grand_total || 0) }}</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">Unpaid:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <span>{{ formatCurrency(+item.unpaid || 0) }}</span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">Methods:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <span v-for="(payment, index) of item.payments" :key="index">-->
<!--                      <span style="padding: 3px"><i :class="{'ti-money': payment.type === 1, 'ti-credit-card': payment.type === 3, 'ti-world': payment.type === 5}"></i></span>-->
<!--                    </span>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">State:</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <div  class="p-d-flex p-flex-wrap p-ai-center">-->
<!--                      <div class="state-badge" :class="SalesInvoiceStates[$i18n.locale].filter(state => state.num === item.state)[0] ? SalesInvoiceStates[$i18n.locale].filter(state => state.num === item.state)[0].color : ''">{{ SalesInvoiceStates[$i18n.locale].filter(state => state.num === item.state)[0] ? SalesInvoiceStates[$i18n.locale].filter(state => state.num === item.state)[0].label : '' }}</div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-mb-3 p-d-flex p-ai-center">-->
<!--                  <div class="p-text-bold mobile-datatable__title">{{ $t('Created') }}</div>-->
<!--                  <div class="mobile-datatable__text p-d-flex p-ai-center">-->
<!--                    <div class="table-date-line">-->
<!--                      <span v-if="item.created_at">{{ formatDate(item.created_at) || '' }}, </span>-->
<!--                      <span v-if="item.creator" class="table-date-line">-->
<!--                        <span v-if="item.creator.type === constants.userTypes.legal_entity && item.creator.company_name">{{ item.creator.company_name }}<span> ({{ item.creator.first_name }} {{ item.creator.last_name }})</span></span>-->
<!--                        <span v-else>{{ item.creator.first_name }} {{ item.creator.last_name }}</span>-->
<!--                      </span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="p-m-2 p-d-flex p-jc-center">-->
<!--                  <EditButton :disabled="disableEditButton && isItemUpdated(item.id)"-->
<!--                              @click="editItem(item)" />-->
<!--                  <DeleteButton @click="confirmItemDelete(item)"-->
<!--                                v-show="$store.state.user.role === constants.userRoles.superAdmin || $store.state.user.role === constants.userRoles.admin"/>-->
<!--                </div>-->
<!--              </td>-->
<!--            </tr>-->
<!--          </template>-->
<!--        </MobileDataTable>-->

        <OwnPaginator v-show="!itemsArrayIsEmpty"
                      :rows="pagination.perPage"
                      :currentPage="pagination.currentPage - 1"
                      :totalPages="pagination.pageCount"
                      :totalRecords="pagination.totalCount"
                      :rowsPerPageOptions="[5,10,25,50,100,200]"
                      :pageLinkSize="11">
        </OwnPaginator>

        <TotalRecordsData :pagination="pagination" :itemsArrayIsEmpty="itemsArrayIsEmpty"/>


<!--        @update-item="updateSalesInvoice"-->
        <SalesInvoiceModal :visible="itemModal"
                           :item="item"
                           :masters="masters"
                           :responsibles="responsibles"
                           :taxes="taxes"
                           :services="services"
                           :suppliers="suppliers"
                           :userEmailAccounts="userEmailAccounts"
                           @update-items="updateItems"
                           @close="closeItemModal">
        </SalesInvoiceModal>

        <ConfirmDeleteModal
            :visible="confirmItemDeleteModal"
            @close="closeConfirmItemDeleteModal"
            @delete-item="deleteItem"
            :data="itemToDeleteData">
        </ConfirmDeleteModal>
			</div>

		</div>
	</div>
</template>

<script>
import httpClient from '@/services/http.services'
import SalesInvoiceStates from '@/translations/states/SalesInvoiceStates'
import SalesInvoiceModal from '@/pages/finance/components/SalesInvoiceModal'
import formatMixins from '@/mixins/formatMixins'
import calculateMixins from '@/mixins/calculateMixins'
import httpMixins from '@/mixins/httpMixins'
import datatableMixins from "@/mixins/datatableMixins";
import GetSalesInvoicePdfButtonSM from "@/pages/finance/components/GetSalesInvoicePdfButtonSM";
import CustomerStatusHistoryButton from "@/pages/users/components/CustomerStatusHistoryButton";
import settings from "@/settings";
import DatesRangeCalendar from "@/components/DatesRangeCalendar";

export default {
  mixins: [ formatMixins, calculateMixins, httpMixins,datatableMixins ],
  components: { GetSalesInvoicePdfButtonSM, SalesInvoiceModal, CustomerStatusHistoryButton,DatesRangeCalendar },
	data() {
		return {
      showOnlyUnpaid: false,
      showUpdatedItemPdfSpinner: false,
      userEmailAccounts: null,
      // disableEditButton: false,
      // masters: [],
      // responsibles: [],
      // taxes: [],
      // services: [],
      // suppliers: [],

      // isLoading: true,
      fieldsExpand: 'customer,customer.customerStatus,customer.address,car,order,creator,order_positions,order_positions.history,order_positions.request_state_history,order_consumable,payments',
      apiLink: 'sales-invoice/get-active-invoices',
      routePath: '/sales-invoices',
      // searchData: '',
      sort: {
        column: 'issued',
        // column: 'id',
        direction: '-',
      },
      defaultSort: {
        column: 'issued',
        // column: 'id',
        direction: '-',
      },
			// items: [],
      // itemModal: false,
      // itemDeleteModal: false,
      // confirmItemDeleteModal: false,
			// item: {},
      // updatedItem: null,
      // itemToDelete: {},
			// submitted: false,
      // pagination: {
      //   totalCount: null,
      //   pageCount: null,
      //   currentPage: 1,
      //   perPage: 25
      // },
      SalesInvoiceStates,
      // constants
		}
	},
  // watch: {
  //   '$route'() {
  //     this.checkRouteQuery()
  //     if (this.$route.path === '/login' || this.$route.path !== '/sales-invoices') {
  //       return false
  //     }
  //     this.getItems()
  //   },
  //   '$store.state.firstLayerIsOpened'() {
  //     if (!this.$store.state.firstLayerIsOpened) {
  //       if (this.itemModal) {
  //         this.closeItemModal()
  //       }
  //       if (this.confirmItemDeleteModal) {
  //         this.closeConfirmItemDeleteModal()
  //       }
  //     }
  //   },
  // },
  watch: {
    '$store.state.user.emailAccounts'() {
      this.generateUserEmailAccounts()
    },
  },
  mounted() {
    if (settings.autoLogoutTimeout && this.$store.state.logoutTimestampIsOverdue) return false
    // this.sort.column = this.defaultSort.column
    // this.sort.direction = this.defaultSort.direction

    this.checkRouteQuery()
    this.getItems()

    this.getModalData()
    // this.getUserCashboxes()

    // if (!this.masters?.length) {
    //   this.getMasters()
    // }
    // if (!this.responsibles?.length) {
    //   this.getResponsibles()
    // }
    // if (!this.taxes?.length) {
    //   this.getTaxes()
    // }
    // if (!this.services?.length) {
    //   this.getServices()
    // }
    // if (!this.suppliers?.length) {
    //   this.getSuppliers()
    // }
    this.generateUserEmailAccounts()
	},
  // unmounted() {
  //   this.items = []
  // },
  methods: {
    changeCustomerStatus(newStatus, userId) {
      if (!userId) return false
      const itemsWithSameUser = this.items?.filter(i => {
        return i.user_id === userId
      })

      if (itemsWithSameUser?.length) {
        itemsWithSameUser.forEach(i => {
          if (i.customer) {
            if (i.customer.customerStatus) {
              i.customer.customerStatus.status_id = newStatus
            } else {
              i.customer.customerStatus = {}
              i.customer.customerStatus.status_id = newStatus
            }
          }
        })
      }
    },
    // async updateSalesInvoice(id) {
    //   this.showUpdatedItemPdfSpinner = true
    //   if (!id) return false
    //   this.updatedItemId = id
    //
    //   let foundItem = this.items?.find(item => item.id === id)
    //   if (foundItem) {
    //     const indexOfFoundItem = this.items.indexOf(foundItem)
    //     if (indexOfFoundItem !== -1) {
    //       const itemData = await this.getSalesInvoiceById(id)
    //       if (itemData) {
    //         this.items[indexOfFoundItem] = itemData
    //       }
    //     }
    //   }
    // },
    // async getSalesInvoiceById(id) {
    //   try {
    //     const { data, status } = await httpClient.post(`sales-invoice/get-by-id?expand=${this.fieldsExpand}`, { id })
    //     if (status === 200 && data?.success && data.item) {
    //       return data.item
    //     } else {
    //       return null
    //     }
    //   } catch(err) {
    //     this.showError(err)
    //   } finally {
    //     this.$store.commit('changeUpdatedOrderId', null)
    //   }
    // },
    // generateUserEmailAccounts() {
    //   if (!this.$store.state.user.emailAccounts?.length) return false
    //   this.userEmailAccounts = this.$store.state.user.emailAccounts.map(account => (
    //       {id: account.accountData?.id, username: account.accountData?.username, sender_name: account.accountData?.sender_name, signature: account.signature}
    //   ))
    // },
    // generatePaymentsTypes(payments) {
    //   console.log(payments)
    //   return payments
    // },
    generatePaymentsMethods(payments) {
      if (!payments) return false
      let paymentsMethods = []
      payments.forEach(payment => {
        if (paymentsMethods.indexOf(payment.type) === -1) {
          paymentsMethods.push(payment.type)
        }
      })
      return paymentsMethods
    },
    // searchItems(searchData) {
    //   this.searchData = searchData
    //   this.$router.push({
    //     route: this.$route.path,
    //     query: {
    //       ...this.$route.query,
    //       search: this.searchData,
    //     }
    //   })
    // },
    // updateItems(id, clearItem = false) {
    //   this.disableEditButton = true
    //   if (id) {
    //     this.updatedItem = id
    //   }
    //   if (clearItem) {
    //     this.item = {}
    //     this.submitted = false
    //   }
    //   this.getItems(false)
    // },
    // resetAllRouterQueries() {
    //   this.pagination.currentPage =  1
    //   this.pagination.perPage = 25
    //   this.sort.column = this.defaultSort.column
    //   this.sort.direction = this.defaultSort.direction
    //   this.searchData = ''
    // },
    // checkRouteQuery() {
    //   if (this.$route.query.page) {
    //     this.pagination.currentPage = +this.$route.query.page
    //   } else {
    //     this.pagination.currentPage = 1
    //   }
    //   if (this.$route.query.perPage) {
    //     this.pagination.perPage = +this.$route.query.perPage
    //   } else {
    //     this.pagination.perPage = 25
    //   }
    //   if (this.$route.query.sort) {
    //     const firstSign = this.$route.query.sort[0]
    //     if (firstSign === '-') {
    //       this.sort.direction = '-'
    //       let sortColumn = this.$route.query.sort.split('')
    //       this.sort.column = sortColumn.splice(1).join('')
    //     } else {
    //       this.sort.direction = ''
    //       this.sort.column = this.$route.query.sort
    //     }
    //   }
    //
    //   if (this.$route.query.search) {
    //     this.searchData = this.$route.query.search
    //   } else {
    //     this.searchData = ''
    //   }
    // },
    // sortItems(columnName) {
    //   if (this.sort.column !== columnName) {
    //     this.sort.direction = ''
    //   } else {
    //     this.sort.direction = this.sort.direction === '' ? '-' : '';
    //   }
    //   this.sort.column = columnName
    //
    //   this.$router.push({
    //     route: this.$route.path,
    //     query: {
    //       ...this.$route.query,
    //       sort: this.sort.direction + this.sort.column
    //     }
    //   })
    // },
    // isItemUpdated(itemId) {
    //   return this.updatedItem === itemId
    // },
    async getItems() {
      this.dataTableIsLoading = true
      this.disableEditButton = true
      try {
        const { status, data, headers } = await httpClient({
          url: this.apiLink,
          method: 'post',
          params: {
            page: this.pagination.currentPage,
            'per-page': this.pagination.perPage,
            sort: this.sort.direction + this.sort.column,
            search: this.processedSearchValue ?? null,
            expand: this.fieldsExpand,
            show_only_unpaid: +this.showOnlyUnpaid,
          },
          data: {
            dateFrom: this.fromTimestamp,
            dateTo: this.toTimestamp,
          }
        })
        if (status === 200 && data) {
          this.pagination.totalCount = +headers['x-pagination-total-count']
          this.pagination.pageCount = +headers['x-pagination-page-count']
          // this.pagination.currentPage = +headers['x-pagination-current-page']
          // this.pagination.perPage = +headers['x-pagination-per-page']
          this.items = data

          if (!this.items?.length) {
            this.pagination.pageCount = 1
            // this.pagination.currentPage = 1
          }
        }

        // if (!this.masters?.length) {
        //   this.getMasters()
        // }
        // if (!this.responsibles?.length) {
        //   this.getResponsibles()
        // }
        // if (!this.taxes?.length) {
        //   this.getTaxes()
        // }
        // if (!this.services?.length) {
        //   this.getServices()
        // }
        // if (!this.suppliers?.length) {
        //   this.getSuppliers()
        // }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
        this.dataTableIsLoading = false
        this.disableEditButton = false
        this.showUpdatedItemPdfSpinner = false
      }
    },
    // async getUserCashboxes() {
    //   try {
    //     const { status, data } = await httpClient('cashbox/get-user-cashboxes')
    //     if (status === 200) {
    //       // const cashboxes = data.map(cashbox => cashbox.cashbox)
    //       const cashboxes = data ?? []
    //
    //       console.log(data)
    //
    //       this.$store.commit('updateUserCashboxes', cashboxes)
    //     }
    //   } catch(err) {
    //     this.showError(err)
    //   }
    // },
    // closeItemModal() {
    //   this.itemModal = false
    //   if (this.$store.state.firstLayerIsOpened === true ) {
    //     this.$store.commit('toggleFirstLayer', false)
    //   }
    //   this.item = {}
    //   this.submitted = false
    // },
    // addNewItem() {
		// 	this.item = {}
		// 	this.submitted = false
		// 	this.itemModal = true
    //   this.$store.commit('toggleFirstLayer', true)
		// },
		// editItem(item) {
    //   this.item = {...item}
    //
    //   if (item.order_consumable) {
    //     this.item.use_consumables = true
    //   } else {
    //     this.item.use_consumables = false
    //   }
    //   // if (item.order_positions) {
    //   //   this.item.positions = item.order_positions
    //   // }
    //
    //   this.itemModal = true
    //   this.$store.commit('toggleFirstLayer', true)
		// },
    // confirmItemDelete(itemToDelete) {
    //   this.itemToDelete = itemToDelete
    //   this.confirmItemDeleteModal = true
    //   this.$store.commit('toggleFirstLayer', true)
    // },
    // closeConfirmItemDeleteModal() {
    //   this.itemToDelete = {}
    //   this.confirmItemDeleteModal = false
    //   this.$store.commit('toggleFirstLayer', false)
    // },
    deleteItem() {
      console.log('delete sales invoice function')
      // try {
      //   const { status } = await httpClient.post('user/delete-user', {id: this.itemToDelete.id})
      //   if (status === 204) {
      //     this.updatedItem = null
      //
      //     this.searchData = ''
      //     this.$router.push({
      //       route: this.$route.path,
      //       query: {
      //         ...this.$route.query,
      //         search: '',
      //       }
      //     })
      //
      //     this.getItems()
      //     this.closeConfirmItemDeleteModal()
      //   }
      // } catch(err) {
      //   console.log(err)
      // }
    },
	},
  computed: {
    allDataLoaded() {
      return !!(this.taxes && this.suppliers)
    },
    // itemsArrayIsEmpty() {
    //     return !(this.items && this.items.length)
    // },
    actionButtonsCount() {
      if (this.$store.state.user.role === this.constants.userRoles.superAdmin || this.$store.state.user.role === this.constants.userRoles.admin) {
        return 2
      } else {
        return 1
      }
    },
    itemToDeleteData() {
      return 'delete data'
    },
  }
}
</script>

<style scoped lang="scss">

</style>