<template>
  <a v-if="position.fixed_qty && +position.fixed_qty"
     @click.prevent="getInvoicesData($event)"
     draggable="false"
     href="#"
     class="p-link table-link-icon">
    <i class="ti-link"></i>
    <span style="font-size: 0.9em; position: relative; top: -1px;">{{ +position.fixed_qty }}</span>
  </a>
  <OverlayPanel :ref="'ppio-' + position.id">
    <Spinner v-if="isLoading" :isSmall="true" :spinnerStyle="'height:60px'"/>
    <div v-else class="overlay-panel-inner">
      <div v-if="fixedRequests?.length" style="line-height: 1.5">
        <div class="title">{{ $t('Purchase invoices') }}:</div>
        <div v-for="(fixedRequest, index) of fixedRequests" :key="fixedRequest.id">
          <div v-if="fixedRequest.invoice_number">
<!--            {{ $t('Invoice')}}:-->
<!--            {{$t('Number')}}:-->
            <router-link v-if="showLink"
                         :to="{ path: '/purchase-invoices', query: { search: fixedRequest.invoice_number } }"
                         target="_blank">
              <span>{{ fixedRequest.invoice_number }}</span>
            </router-link>
            <span v-else>{{ fixedRequest.invoice_number }}</span>
<!--            <span> - {{$t('Qty')}}: {{ fixedRequest.qty }}</span>-->
<!--            <div>{{$t('Qty')}}: {{ fixedRequest.qty }}</div>-->
            <div>{{$t('Qty')}}: {{ fixedRequest.qty }}</div>
          </div>
          <!--        <div>{{ $t('Created at') }}: {{ formatDate(invoice.created_at) }}</div>-->
          <hr v-show="index < fixedRequests.length - 1">
        </div>
      </div>
      <div v-else>{{ $t('No data available') }}</div>
    </div>
  </OverlayPanel>
</template>

<script>
import Spinner from "@/components/Spinner";
import httpClient from "@/services/http.services";
import constants from "@/constants";
// import httpMixins from "@/mixins/httpMixins";
import showErrorsMixins from "@/mixins/showErrorsMixins";

export default {
  name: 'PositionFixedQtyButton',
  mixins: [showErrorsMixins],
  components: { Spinner },
  props: {
    position: Object,
    isReducedPosition: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      fixedRequests: null,
    }
  },
  methods: {
    async getInvoicesData($event) {
      if (!this.position) return
      let orderPositionId = this.position.id
      let reducedPositionId = null

      if (this.isReducedPosition) {
        orderPositionId = this.position.order_position_id
        reducedPositionId = this.position.id
      }

      this.toggleFixedRequestsOverlay($event, this.position.id)

      if (!this.$refs['ppio-' + this.position.id.toString()].visible) return false

      this.isLoading = true

      // const apiLink = this.isReducedPosition ? 'purchase-invoice/get-reduced-position-invoices' : 'purchase-invoice/get-request-position-invoices'
      const apiLink = 'purchase-invoice/get-fixed-request-positions'

      try {
        const { data, status } = await httpClient.post(apiLink, { orderPositionId, reducedPositionId })
        // console.log(data)
        if (status === 200 && data) {
          this.fixedRequests = data
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.isLoading = false
      }
    },
    toggleFixedRequestsOverlay(event, id) {
      if (!event || !id) {
        return false
      }
      this.$refs['ppio-' + id.toString()].toggle(event);
    },
  },
  computed: {
    showLink() {
      return !!(this.$store.state.user.role === constants.userRoles.superAdmin ||
          this.$store.state.user.role === constants.userRoles.admin ||
          this.$store.state.user.role === constants.userRoles.accountant)
    }
  }
  // methods: {
  //   calculateFixedQty(position) {
  //     const fixedQty = +position.fixed_qty > +position.qty ? +position.qty : +position.fixed_qty
  //     return fixedQty
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.overlay-panel-inner {
  min-width: 110px!important;
}
.title {
  padding-bottom: 10px;
  font-weight: 500;
}
</style>